<template>
  <v-app>
    <v-main style="padding: 0px 0px 0px 0px">
      <v-container fluid class="contenedor-todo">
        <v-row>
          <Menu></Menu>

          <div class="content-col">
            <v-toolbar
              dense
              class="custom-toolbar justify-center"
              flat
              color="#cacaca"
            >

              <v-spacer></v-spacer>

              <v-spacer></v-spacer>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-1 font-weight-bold"
                    plain
                    v-bind="attrs"
                    v-on="on"
                  >
                    Contacto
                  </v-btn>
                </template>
                <v-list dense>
                  <v-subheader class="font-weight-bold">
                    <strong>
                      Centro de Atención a Distancia(CAD):
                    </strong>
                  </v-subheader>
                  <v-list-item
                    style="height: 40px"
                    v-for="contacto in contactos"
                    :key="contacto.title"
                  >
                    <v-list-item-avatar>
                      <v-icon :color="contacto.color">{{
                        contacto.icon
                      }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>{{ contacto.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-container fluid style="padding-bottom: 60px">
              <v-row v-if="this.$route.path == '/principal'">
                <v-col cols="6">
                  <div class="ml-2 font-weight-bold" style="font-size: 28px">
                    Detalles de la cuenta
                  </div>
                  <v-row class="mt-4">
                    <v-col
                      md="4"
                      v-for="estadistica in estadisticas"
                      :key="estadistica.tabla"
                    >
                      <v-card
                        class="mx-auto"
                        color="claro"
                        dark
                        :to="AbrirDesdeInicio(estadistica.tabla)"
                      >
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="mb-2 text-h6"
                              style="text-transform: capitalize"
                            >
                              {{ estadistica.tabla }}
                            </v-list-item-subtitle>
                            <v-list-item-title
                              class="text-h4"
                              style="
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              {{ estadistica.cantidad }}
                              <v-icon
                                x-large
                                dark
                                style="font-size: 40px"
                                color="white"
                                class="text-right"
                                >{{ estadistica.icono }}</v-icon
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col> 
                <v-col cols="6">
                  <div class="ml-2 font-weight-bold" style="font-size: 28px; color: #333;">
                    Agenda
                  </div>
                  
                  <!-- Verificar si la lista de agendas está vacía -->
                  <template v-if="agendas.length == 0">
                    <v-card class="mt-6" flat>
                      <v-list-item class="d-flex align-center">
                        <v-list-item-content class="ml-4" style="flex: 1; text-align: center;">
                          <v-list-item-title class="text-h5 mb-2 font-weight-medium" style="color: #333;">
                            No hay resultados
                          </v-list-item-title>
                          <v-list-item-subtitle style="color: #666;">
                            No se encontraron eventos en la agenda.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </template>

                  <!-- Mostrar las agendas si no está vacía -->
                  <v-card v-else class="mt-6" flat v-for="agenda in agendas" :key="agenda.pkagenda">
                    <v-list-item class="d-flex align-start">
                      <v-card width="60" class="mt-2 mb-2">
                        <div class="calendar-item">
                          <span class="calendar-month" style="color: white">
                            {{ new Date(agenda.fecha).toLocaleString('es-ES', { month: 'short' }).toUpperCase() }}
                          </span>
                          <span class="calendar-date">{{ new Date(agenda.fecha).getDate() }}</span>
                        </div>
                      </v-card>
                      <v-list-item-content class="ml-4" style="flex: 1; word-wrap: break-word;">
                        <v-list-item-title class="text-h5 mb-2 font-weight-medium" style="color: #333;">
                          {{ agenda.titulo }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="color: #666; white-space: normal; overflow: visible;">
                          {{ agenda.descripcion }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="pt-2">
                        <a :href="agenda.link" target="_blank" style="text-decoration: none; color: #1976D2;">
                          Visitar sitio
                        </a>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card>
                </v-col>

              </v-row>
              <router-view style="flex-grow: 1" />
            </v-container>
          </div>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "@/components/menu.vue";

export default {
  data: () => ({
    contactos: [
      {
        title: "atencionadistancia@lacosta.gob.ar",
        color: "blue",
        icon: "mdi-email",
      },
      { title: "02246-433025", color: "teal", icon: "mdi-phone" },
      { title: "02246-433003", color: "teal", icon: "mdi-phone" },
      { title: "02246-433071", color: "teal", icon: "mdi-phone" },
      { title: "02246-433036", color: "teal", icon: "mdi-phone" },
    ],
    estadisticas: [],
    agendas: []
  }),

  updated() {
    console.log("actualiza", this.$route);
  },

  mounted() {
    this.TraerEstadisticas();
  },

  methods: {
    TraerEstadisticas() {
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}users/traerEstadisticas`)
        .then(function (response) {
          console.log("traer estadisticas", response);
          obj.estadisticas = response.data;
          obj.TraerAgendas();
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },
    TraerAgendas() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}funciones/Agenda`)
        .then(function (response) {
          console.log("traer agendas", response);
          obj.agendas = response.data;
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },
    AbrirDesdeInicio(sistema) {
      switch (sistema) {
        case "cementerio":
          return "/cementerio";
        case "comercios":
          return "/comercio";
        case "vehiculos":
          return "/vehiculos";
        case "inmuebles":
          return "/inmueble";
        case "escribanos":
          return "/escribanos";
        default:
          return "/obrasprivadas";
      }
    },
  },

  components: {
    Menu,
  },

  computed: {

  },

  //Actualizar el inicio cada vez que entra al principal
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.TraerEstadisticas();
    });
  },

  beforeRouteUpdate(to, from, next) {
    this.TraerEstadisticas();
    next();
  },
};
</script>
<style>
.v-data-table__expanded__content {
  background-color: #4f798f6e !important;
  height: 320px !important;
}
.bordecolor {
  border: 2px solid #4f798f !important;
}
.radiocard {
  border-radius: 30px !important;
}
.toolbardatos {
  height: 135px !important;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.color {
  color: #40a5dd !important;
}
.footertotal {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  align-items: center;
}
.footerpago {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  align-items: center;
}
.toolbarprincipal .v-toolbar__content {
  padding-right: 0px !important;
}
.margen {
  margin-top: -25px;
}
.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(
    5px
  ); /* Ajusta el valor de desenfoque según lo necesites */
  z-index: 1;
}

.alert-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.alert {
  z-index: 2;
}

.error-alert {
  animation: shake 0.5s ease-in-out infinite alternate;
  background-color: #ffcccc !important;
  padding: 30px;
}
.ancho_tabla {
  width: 100%;
}
.tabla {
  border: 2px solid;
  border-color: #4f798f;
}
.v-btn {
  letter-spacing: 0;
}
.botones_pagar {
  width: 95px;
}
/* .letra-custom {
  font-family: inherit;
  font-style: italic;
} */
.v-data-table-header .v-simple-checkbox .v-icon {
  color: white; /* Cambia el color del ícono a blanco */
}
.custom-header-style .v-data-table-header {
  background-color: #272727; /* Cambia este valor al color que desees */
}
.disabled-row {
  background-color: #f5f5f5; /* Puedes cambiar el color para que parezca deshabilitada */
  color: #b0b0b0; /* Cambia el color del texto si lo deseas */
  pointer-events: none; /* Desactiva todos los eventos en la fila */
}
</style>
<style scoped>
.contenedor-todo {
  background: #d9d9d9;
  overflow-y: auto;
}
.custom-toolbar {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.divtoolbar {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.dividers {
  width: 85%;
  border: 1px solid;
  border-color: #638699;
}

.content-col {
  flex-grow: 1;
  padding-left: 56px;
  height: 100vh;
}

.calendar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: #4f798f;
}

.calendar-month {
  font-size: 18px;
  font-weight: medium;
  width: 100%;
  background-color: #4f798f;
  text-align: center;
}

.calendar-date {
  font-size: 24px;
  font-weight: bold;
  color: #4f798f;
}
</style>
<style lang="scss">
// #app {
//   // font-family: "Roboto", sans-serif !important;
//   // font-family: "Scout", sans-serif !important;
// }
html {
  overflow: auto;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#rcorners2 {
  border-radius: 25px;
  border: 2px solid #73ad21;
  padding: 20px;
  width: 200px;
  height: 150px;
}
</style>
