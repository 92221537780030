import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import { VueMaskDirective } from 'v-mask'
import axios from 'axios'
// import token_dev from "./token.js";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
export const bus = new Vue();

require('moment/locale/es')

Vue.use(VueMoment, {
  moment,
});

Vue.directive('mask', VueMaskDirective);

// Configuración global de SweetAlert2 con Vuetify
const swalOptions = {
  customClass: {
    // cancelButton: "btn btn-danger v-btn--outlined",
    // confirmButton: "v-btn v-btn--color-success v-btn--outlined"
    cancelButton: "btn btn-danger",
    confirmButton: "btn btn-success"
  },
  // buttonsStyling: false
};

Vue.use(VueSweetalert2, swalOptions);


axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");


const service = axios.create({
  timeout: 120000 // request timeout
});

const globalData = Vue.observable({
  isLoading: false
})

Vue.prototype.$globalData = globalData;
// service.interceptors.response.use(
//   function(response) { return response;}, 
//   function(error) {
//       // handle error
//       if (error.response) {
//           alert(JSON.stringify(error.response.status));
//       }
      

// });


service.interceptors.request.use(config => {
  globalData.isLoading = true;
  return config;
}, error => {
  return Promise.reject(error);
})

service.interceptors.response.use(response => {
  globalData.isLoading = false;
  return response;
}, error => {
  globalData.isLoading = false;
  return Promise.reject(error);
})

Vue.config.productionTip = false;
Vue.prototype.$ajax = service;
Vue.prototype.$apitramites = "https://backend.tramites.lacosta.gob.ar/";
// Vue.prototype.$apirecursos = "https://backend.sim.lacosta.gob.ar/recursos/";
// Vue.prototype.$apiSim = "https://backend.sim.lacosta.gob.ar/";
// Vue.prototype.$apitramites = "http://11.11.15.171:4000/";
Vue.prototype.moment = moment;


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
