<template>
<v-app>
  <v-container fluid fill-height class="ma-0">
      <v-layout justify-center align-center>
        <v-flex text-center>
          <v-img :src="backgroundImage" class="fondo" style="width: 100%; height: 100%; object-fit: cover;"></v-img>
          <v-card class="pa-10 ma-4 mx-0 my-0" flat>
            <v-card-subtitle class="headline">ADVERTENCIA: Ud. está saliendo de la plataforma Municipal para ingresar al entorno de Macro Click</v-card-subtitle>
            <!-- {{formulario}} -->
            <div>
              <form method="post" id="formulario" action="https://botonpp.asjservicios.com.ar/" >
              <div style="display: none">
                CallbackSuccess		    <input name="CallbackSuccess" id="CallbackSuccess" :value="formulario.callbackSuccess"/><br>
                CallbackCancel		    <input name="CallbackCancel" id="CallbackCancel" :value="formulario.callbackCancel"/><br>
                Comercio					    <input name="Comercio" :value="formulario.comercio"/><br>
                SucursalComercio	    <input name="SucursalComercio" id="SucursalComercio" :value="formulario.sucursalComercio"/><br>
                TransaccionComercioId	<input name="TransaccionComercioId" id="TransaccionComercioId" :value="formulario.numRecibos"/><br>
                Monto						<input name="Monto" id="Monto" :value="formulario.montoEncriptado" /><br>
                <input  name="Producto[0]" :value="formulario.monto"/>
                UserId						
                <input name="UserId" id="UserId" :value="formulario.id_user"/><br>
                </div>
                <v-btn x-large color="error" class="mr-2" @click="CerrarPagina()">
                  cancelar
                </v-btn>
                <v-btn x-large color="claro" type="submit" id="btnenviarsolicitud" dark>Confirmar</v-btn>
              <!-- </v-form> -->
              </form>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
</v-app>
    
</template>

<script>
// import { bus } from '@/main';
export default {
  data() {
    return {
        backgroundImage: require('../assets/fondos/fondo_tramites.jpg'),
        formulario:{},
        validform:true,
        numeroAleatorio:""
    };
  },
  mounted(){
    // console.log("hola", this.$route.query)
    this.formulario=this.$route.query;
    this.numeroAleatorio= Math.floor(Math.random() * 1000) + 1;
    console.log("aleatorio", this.numeroAleatorio)
    console.log("formulario", this.$route);
  },
  methods:{
    // CargarFormulario(datos){
    //   this.formulario=datos;
    // },
    CerrarPagina(){
      // this.$router.go(-1);
      window.close();
    },
  }
};
</script>

<style scoped>
.fondo{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    filter: blur(5px);
    z-index: -999px;
}
</style>