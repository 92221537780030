var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formtramite",staticClass:"formu",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.EnviarSolicitud.apply(null, arguments)}},model:{value:(_vm.validform),callback:function ($$v) {_vm.validform=$$v},expression:"validform"}},[_c('v-toolbar',{attrs:{"dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Titular")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"readonly":_vm.user.disabled,"label":"DNI/CUIT","rules":[
                            (v) =>
                            !!v ||
                            'Falta el dni/cuit',
                        ],"required":"","value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].dni_tit : '',"name":"titular_dni","dense":"","filled":"","outlined":""}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"readonly":_vm.user.disabled,"value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].nombre_tit : '',"label":"NOMBRE","rules":[
                            (v) =>
                            !!v ||
                            'Falta el nombre',
                        ],"required":"","name":"titular_nombre","dense":"","filled":"","outlined":""}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"readonly":_vm.user.disabled,"value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].apellido_tit : '',"label":"APELLIDO","rules":[
                            (v) =>
                            !!v ||
                            'Falta el apellido',
                        ],"required":"","name":"titular_apellido","dense":"","filled":"","outlined":""}})],1)],1)],1),_c('v-toolbar',{attrs:{"dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Formulario")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"readonly":_vm.user.disabled,"value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].di_calle : '',"label":"CALLE","rules":[
                            (v) =>
                            !!v ||
                            'Falta la calle',
                        ],"required":"","name":"calle","dense":"","filled":"","outlined":""}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-text-field',{attrs:{"readonly":_vm.user.disabled,"value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].di_nro : '',"label":"N°","rules":[
                            (v) =>
                            !!v ||
                            'Falta el número',
                        ],"required":"","name":"num_calle","dense":"","filled":"","outlined":""}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-text-field',{attrs:{"readonly":_vm.user.disabled,"value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].di_piso : '',"label":"PISO","name":"piso","dense":"","filled":"","outlined":""}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-text-field',{attrs:{"readonly":_vm.user.disabled,"value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].di_dpto : '',"label":"DPTO","name":"dpto","dense":"","filled":"","outlined":""}})],1),_c('v-col',{staticClass:"margen",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"readonly":"","value":(_vm.datosformulario.padron) ? _vm.datosformulario.padron[0].cuenta+'/'+_vm.datosformulario.padron[0].d_vefi : '',"label":"CUENTA","rules":[
                            (v) =>
                            !!v ||
                            'Falta la cuenta',
                        ],"required":"","name":"cuenta_oc","dense":"","filled":"","outlined":""}})],1),_c('v-col',{staticClass:"margen",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"readonly":_vm.user.disabled,"value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].insdominio : '',"label":"N° DOMINIO","rules":[
                            (v) =>
                            !!v ||
                            'Falta el número',
                        ],"required":"","name":"dominio","dense":"","filled":"","outlined":""}})],1),_c('v-col',{staticClass:"margen",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"readonly":_vm.user.disabled,"value":_vm.datosformulario.tramites && _vm.datosformulario.tramites[0] ? _vm.datosformulario.tramites[0].part_prov : (_vm.datosformulario.padron ? _vm.datosformulario.padron[0].part_prov : ''),"label":"PARTIDA PROVINCIAL","rules":[
                            (v) =>
                            !!v ||
                            'Falta la partida provincial',
                        ],"required":"","name":"partida","dense":"","filled":"","outlined":""}})],1),_c('v-col',{staticClass:"margen",attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.user.disabled,"items":_vm.categorias,"value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].categoria : '',"item-text":"t_deta","item-value":"t_cod","placeholder":"Seleccione una categoría","rules":[
                        (v) =>
                        !!v ||
                        'Seleccione una categoría',
                        ],"required":"","name":"categoria","outlined":"","filled":"","dense":"","label":"CATEGORÍA"}})],1),_c('v-col',{staticClass:"margen",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"readonly":_vm.user.disabled,"disabled":_vm.user.disabled,"value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].observaciones : '',"label":"OBSERVACIONES","name":"observacion","dense":"","filled":"","outlined":""}})],1),_c('v-col',{staticClass:"margen",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"readonly":_vm.user.disabled,"disabled":_vm.user.disabled,"value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].motivo : '',"label":"MOTIVO","name":"motivo","dense":"","filled":"","outlined":""}})],1),_c('v-col',{staticClass:"margen",attrs:{"md":"12"}},[_c('v-textarea',{attrs:{"readonly":_vm.user.disabled,"value":(_vm.datosformulario.tramite) ? _vm.datosformulario.tramite[0].deslinde : '',"label":"DESLINDE","rules":[
                            (v) =>
                            !!v ||
                            'Falta la deslinde',
                        ],"required":"","name":"deslinde","outlined":"","dense":"","filled":""}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"dark":"","color":"error"},on:{"click":function($event){return _vm.vaciarExpanded()}}},[_vm._v(" cancelar ")]),(!_vm.user.disabled)?_c('v-btn',{attrs:{"dark":"","color":"claro","type":"submit","id":"btnenviarsolicitud"}},[_vm._v(" CONFIRMAR ")]):_vm._e()],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"top":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbarconfirm = false}}},'v-btn',attrs,false),[_vm._v(" cerrar ")])]}}]),model:{value:(_vm.snackbarconfirm),callback:function ($$v) {_vm.snackbarconfirm=$$v},expression:"snackbarconfirm"}},[_vm._v(" "+_vm._s(_vm.msj)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }