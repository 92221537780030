<template>
<!-- <v-app style="width:100%;">
  <v-container fluid> -->
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headerscomercios"
            :items="comercios"
            :search="buscador"
            class="elevation-1 bordecolor"
            item-key="pkcomercio"
            :expanded.sync="expanded"
            :show-expand="(expanded=='') ? false : true"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="claro"
                dark
              >
                <v-toolbar-title >COMERCIOS</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="buscador"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  outlined
                  filled
                  dense
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  color="white"
                  style="margin-top: 0px; margin-left: 15px;"
                  @click="DialogNuevocomercio=true"
                >
                  <h4 style="color:#000;">Nuevo comercio</h4>
                </v-btn>
              </v-toolbar>
            </template>
            <template
              v-slot:[`item.estado`]="{
                item,
              }"
            >
              <span :class="laclase(item).class">
                {{item.condi}}
              </span>
            </template>
            <template v-slot:[`item.editar`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="background-color: #000; width: 120%; height: 60%; border-radius: 5px;"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="expandedIsActive"
                      small
                      dark
                      @click="showDetails = 'editcomer', expanded = [item]; editardescripcion=item.descripcion, padron=item.padron, pkcomercio=item.pkcomercio"
                    >
                      mdi-pencil
                    </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="margin-left: 17px;"
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogConsultas=true, pkcomercio=item.cuenta, datoscomercio=item"
                    v-if="!laclase(item).restringido"
                  >
                    mdi-home-search
                  </v-icon>
                </template>
                <span>Consultas</span>
              </v-tooltip> -->
              <v-tooltip bottom v-if="item.condicontri && item.iniciogc">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style=" background-color: #000;border-radius: 5px; height: 33px; width: 35px;"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="expandedIsActive"
                    dark
                    small
                    @click="showDetails = 'declaracionjurada', expanded = [item], datoscomercio=item, AbrirDialogDeclaracionJurada()"
                  >
                    mdi-file
                  </v-icon>
                </template>
                <span>Declaración Jurada</span>
              </v-tooltip>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style=" background-color: #40A5DD;border-radius: 5px; height: 33px; width: 35px;margin-left: 17px;"
                    v-bind="attrs"
                    v-on="on"
                    dark
                    small
                    @click="showDetails = 'presentacionanual', expanded = [item], datoscomercio=item, AbrirDialogPresentacionAnual()"
                  >
                    mdi-account-details
                  </v-icon>
                </template>
                <span>Presentacion Anual</span>
              </v-tooltip> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style=" background-color: #000;border-radius: 5px; height: 33px; width: 35px;margin-left: 10px;"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="expandedIsActive"
                    dark
                    small
                    @click="showDetails = 'pagarplandepagos', expanded = [item], datoscomercio=item, AbrirDialogPlanDePagos()"
                  >
                    mdi-cash-multiple
                  </v-icon>
                </template>
                <span>Plan de Pagos</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style=" background-color: #000;border-radius: 5px; height: 33px; width: 35px;margin-left: 10px;"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="expandedIsActive"
                    dark
                    small
                    @click="showDetails = 'pagardeudas', expanded = [item], datoscomercio=item, AbrirDialogDeudas()"
                  >
                    mdi-currency-usd
                  </v-icon>
                </template>
                <span>Pagar deudas</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style=" background-color: #000;border-radius: 5px; height: 33px; width: 35px;margin-left: 10px;"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="expandedIsActive"
                    dark
                    small
                    @click="showDetails = 'cuotaspagas', expanded = [item], datoscomercio=item, AbrirDialogPagas()"
                  >
                    mdi-cash-check
                  </v-icon>
                </template>
                <span>Cuotas Pagas</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style=" background-color: #000;border-radius: 5px; height: 33px; width: 35px;margin-left: 10px;"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="expandedIsActive"
                    dark
                    small
                    @click="ConstanciaHabilitacion(item)"
                  >
                    mdi-card-account-details-outline
                  </v-icon>
                </template>
                <span>Constancia de Habilitación</span>
              </v-tooltip>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="margin-left: 17px;"
                    v-bind="attrs"
                    v-on="on"
                    @click="DialogEditarcomercio=true; editardescripcion=item, pkcomercio=item.pkcomercio"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar comercio</span>
              </v-tooltip> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="margin-left: 17px; background-color: red;border-radius: 5px; height: 33px; width: 35px;"
                    v-bind="attrs"
                    dark
                    small
                    v-on="on"
                    :disabled="expandedIsActive"
                    @click="EliminarComercio(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Eliminar Comercio</span>
              </v-tooltip>
            </template>
            <template v-slot:[`expanded-item`]="{ headers}">
              <td :colspan="headers.length" style="padding:0px!important;">
                <v-container fluid>
                  <v-row no-gutters justify="center">
                    <v-card min-width="500" v-if="showDetails=='editcomer'" class="bordecolor">
                      <v-card-title class="headline">
                        <v-row justify="center" style="margin-top:5px;">
                            <v-icon
                            style="background-color: #BDBDBD; width: 30px; height: 30px; border-radius: 5px; margin-right:10px;"
                            dark
                            >
                              mdi-pencil
                            </v-icon> 
                            <b>Editar Comercio</b>
                          </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field
                          v-model="editardescripcion"
                          label="Descripcion"
                          class="mt-6"
                          outlined
                          filled
                          dense
                          :rules="[
                                      (v) =>
                                      !!v ||
                                      'Falta la descripcion',
                                  ]"
                          required
                        ></v-text-field>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                      <!-- <v-btn
                          text
                          @click="DialogEditarcomercio = false"
                      >
                          Cancelar
                      </v-btn> -->
                      <v-btn dark
            color="error" @click="expanded=[]" class="mr-2">
                        cancelar
                      </v-btn>
                      <v-btn
                          color="claro"
                          dark
                          @click="Editarcomercio()"
                      >
                          Confirmar
                      </v-btn>
                      </v-card-actions>
                    </v-card>
                    <v-card class="ancho_tabla" v-if="showDetails=='declaracionjurada'">
                      <declaracionjurada ref="formDeclaracion" @vaciarExpanded="vaciarExpanded" :user="componentedeclaracion"></declaracionjurada>
                    </v-card>
                    <v-card class="ancho_tabla" v-if="showDetails=='pagardeudas'">
                      <pagardeudas ref="formDeuda" @vaciarExpanded="vaciarExpanded" :user="componentepagardeuda"></pagardeudas>
                    </v-card>
                    <v-card class="ancho_tabla" v-if="showDetails=='cuotaspagas'">
                      <cuotaspagas ref="formcuotaspagas" @vaciarExpanded="vaciarExpanded" :user="componentepagardeuda"></cuotaspagas>
                    </v-card>
                    <v-card class="ancho_tabla" v-if="showDetails=='pagarplandepagos'">
                      <pagarplandepagos ref="pagarplandepagosform" @vaciarExpanded="vaciarExpanded" :user="componentepagardeuda"></pagarplandepagos>
                    </v-card>
                    <!-- <v-card min-width="1000" v-if="showDetails=='presentacionanual'">
                      <presentacionanual ref="formPresentacion" @vaciarExpanded="vaciarExpanded" :user="componentepresentacion"></presentacionanual>
                    </v-card> -->
                  </v-row>
                </v-container>
              </td>
            </template>
            <template v-slot:no-data>
              <v-btn dark color="claro" @click="initialize">Cargar</v-btn>
            </template>
          </v-data-table>
          <v-dialog
            v-model="dialogConsultas"
            persistent
            max-width="500"
          >
            <v-card>
              <v-card-title class="headline">
                <v-col cols="12">
                  <v-btn
                    text
                    @click="pagardeuda=true, plandepagos=false,declaracionjurada=false, presentacionanual=false"
                  >
                    Consultar / Pagar Deuda
                  </v-btn>
                </v-col>
                <v-col cols="12" style="margin-top: -30px;">
                  <v-btn
                    text
                    @click="plandepagos=true, pagardeuda=false, declaracionjurada=false, presentacionanual=false"
                  >
                    Consultar / Pagar Plan de Pagos
                  </v-btn>
                </v-col>
                <!-- <v-col cols="12" style="margin-top: -30px;">
                  <v-btn
                    text
                    @click="codigopagoelectronico=true, plandepagos=false, pagardeuda=false"
                  >
                    Consultá tu Código de Pago Electrónico
                  </v-btn>
                </v-col> -->
                <v-col cols="12" style="margin-top: -30px;">
                  <v-btn
                    text
                  >
                    Consultar cuotas pagas
                  </v-btn>
                </v-col>
                <cuotaspagas ref="cuotaspagasform" :user="componentepagardeuda"></cuotaspagas>
                <v-col cols="12" style="margin-top: -30px;">
                  <v-btn
                    text
                  >
                    Constancia de Habilitación
                  </v-btn>
                </v-col>
                <v-col cols="12" style="margin-top: -30px;">
                  <v-btn
                    text
                    @click="declaracionjurada=true, pagardeuda=false,plandepagos=false,presentacionanual=false"
                  >
                    Declaración Jurada / Rectificativa
                  </v-btn>
                </v-col>
                <declaracionjurada ref="declaracionjuradaform" :user="componentepagardeuda"></declaracionjurada>
                <v-col cols="12" style="margin-top: -30px;">
                  <v-btn
                    text
                    @click="presentacionanual=true, pagardeuda=false,plandepagos=false,declaracionjurada=false"
                  >
                    Presentación Anual
                  </v-btn>
                </v-col>
                <!-- <presentacionanual ref="presentacionanualform" :user="componentepagardeuda"></presentacionanual> -->
                <v-col cols="12" style="margin-top: -30px;">
                  <v-btn
                    text
                  >
                    Prestación
                  </v-btn>
                </v-col>
                <v-col cols="12">
                <v-divider></v-divider>
                </v-col>
              </v-card-title>
              <v-card-text>
                <v-row v-if="this.pagardeuda==true">
                  <v-col cols="6">
                    <b>Fecha Vencimiento</b>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="datevencimiento"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          style="margin-top: -10px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="datevencimiento"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                      v-model="pagomensual"
                    >
                    <v-radio
                      label="Pago Mensual"
                      value="1"
                    ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="this.plandepagos==true">
                  <v-col cols="6">
                    <b>Fecha Vencimiento</b>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="datevencimiento2"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          style="margin-top: -10px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="datevencimiento2"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row v-if="this.declaracionjurada==true">
                  <v-col cols="6">
                    <b>Fecha Vencimiento</b>
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="datevencimiento3"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          style="margin-top: -10px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="datevencimiento3"
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogConsultas = false"
                >
                  Cerrar
                </v-btn>
                <v-btn color="primary" v-if="this.pagardeuda==true" @click="AbrirDialogDeudas()">
                  Siguiente
                </v-btn>
                <pagardeudas ref="pagardeudasform" :user="componentepagardeuda"></pagardeudas>
                <v-btn color="primary" v-if="this.plandepagos==true" @click="AbrirDialogPlanDePagos()">
                  Siguiente
                </v-btn>
                <v-btn color="primary" v-if="this.declaracionjurada==true" @click="AbrirDialogDeclaracionJurada()">
                  Siguiente
                </v-btn>
                <v-btn color="primary" v-if="this.presentacionanual==true" @click="AbrirDialogPresentacionAnual()">
                  Siguienteeeee
                </v-btn>
                <pagarplandepagos ref="pagarplandepagosform" :user="componentepagardeuda"></pagarplandepagos>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        
        <v-dialog
          v-model="DialogNuevocomercio"
          width="500"
          persistent
        >
          <v-card class="bordecolor">
          <v-form @submit.prevent="AgregarComercio" ref="formnuevocomercio" v-model="validformnuevocomercio">
            <v-card-title class="font-weight-bold">
              <v-row justify="center" style="margin-top:5px;">
                Agregar Nuevo Comercio
              </v-row>
            </v-card-title>
            <v-card-text>
                <v-row class="mt-4">
                  <v-col cols="12">
                    <v-text-field
                      label="Padrón"
                      name="padron"
                      :rules="[
                                (v) =>
                                !!v ||
                                'Falta el padrón',
                            ]"
                      required
                      color="#40A5DD"
                      outlined
                      filled
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" style="margin-top: -15px;">
                    <v-text-field
                      name="descripcion"
                      label="Descripcion"
                      placeholder="Nombre de referencia"
                      :rules="[
                                (v) =>
                                !!v ||
                                'Falta la descripcion',
                            ]"
                      required
                      color="#40A5DD"
                      outlined
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>           
                <v-checkbox 
                name="titular"
                v-model="checkboxtitular"
                :value="1"
                color="#40A5DD"
                >
                  <template v-slot:label>
                    <!-- <div> -->
                      Declaro ser titular / apoderado. Es necesario para solicitar determinados trámites.
                    <!-- </div> -->
                  </template>
                </v-checkbox>
            </v-card-text>
            <!-- <v-divider></v-divider> -->
            <v-card-actions class="justify-center">
              <v-btn color="error" @click="DialogNuevocomercio = false">
                cancelar
              </v-btn>
              <v-btn
                color="claro"
                dark
                type="submit"
                id="botonagregarcomercio"
              >
                Guardar
              </v-btn>
              
            </v-card-actions>
          </v-form>    
          </v-card>
        </v-dialog>
      </v-row>
    <!-- </v-container> -->

<!-- </v-app> -->
</template>

<script>
//import func from 'vue-editor-bridge';
//   import {json2excel} from 'js2excel';
import pagardeudas from "../Comercios/Pagar_deuda_comercio";
import pagarplandepagos from "../Comercios/Pagar_plandepagos_comercio";
import cuotaspagas from "../Comercios/Cuotas_pagas_comercio";
import declaracionjurada from "../Comercios/Declaracion_jurada_comercio";
// import presentacionanual from "../Comercios/Presentacion_anual_comercio";
export default {
  data: () => ({
    timeout:3000,
    msj:"",
    expanded:[],
    showDetails: "",
    headerscomercios: [
      { text: "DESCRIPCION", align: "start", value: "descripcion" },
      { align: "start", value: "editar", width: 55},
      { text: "PADRON", value: "padron", align: "start" },
      { text: "RAZÓN SOCIAL", value: "razosoci", align: "start" },
      { text: "ESTADO", value: "estado", align:"center" },
      // { text: "EDITAR", value: "actions",sortable: false },
      { text: 'OPCIONES', value: 'actions',align: "right",width: 300, sortable: false},
    ],
    comercios:[],
    comercio:"",
    buscador:"",
    dialogConsultas: false,
    datevencimiento: new Date().toISOString().substr(0, 10),
    datevencimiento2: new Date().toISOString().substr(0, 10),
    datevencimiento3: new Date().toISOString().substr(0, 10),
    menu: false,
    menu2: false,
    menu3: false,

    DialogNuevocomercio:false,
    padron:"",
    descripcion:"",
    checkboxtitular:false,
    validformnuevocomercio:true,
    pkcomercio: "",

    editardescripcion:"",


    pagomensual: "1",
    pagardeuda:false,
    plandepagos:false,
    declaracionjurada:false,
    codigopagoelectronico: false,
    
    datoscomercio:{},
    presentacionanual:""
  }),

  components: {
    pagardeudas,
    pagarplandepagos,
    cuotaspagas,
    declaracionjurada,
    // presentacionanual
  },

  computed: {
    componentepagardeuda(){
      return {vencimiento:this.datevencimiento, datoscomercio:this.datoscomercio}
    },
    componentepresentacion(){
      return {pkcomercio:this.datoscomercio.pkcomercio, datoscomercio:this.datoscomercio}
    },
    componentedeclaracion(){
      var tipo
      if(this.datoscomercio.condicontri=='3' && this.datoscomercio.iniciogc!='0000-00-00'){
        tipo = "gc"
      }else if(this.datoscomercio.condicontri=='4' && this.datoscomercio.iniciogc!='0000-00-00'){
        tipo = "gp"
      }else if(this.datoscomercio.condicontri=='5' && this.datoscomercio.iniciogc!='0000-00-00'){
        tipo = "ar"
      }
      return {vencimiento:this.datevencimiento, datoscomercio:this.datoscomercio, tipo:tipo}
    },
    expandedIsActive() {
      return this.expanded.length > 0;
    }
    // codigo(){
    //   return `089${this.datoscomercio.padron.toString().padStart(7,"0")}${this.datoscomercio.d_vefi}`;
    // }
  },

  watch: {
    
    
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.ListadoComercio();
    },

    ListadoComercio(){
      var obj = this;
      this.$ajax
      .get(`${this.$apitramites}comercios/traerComercios`)
      .then(function(response) {
        console.log("Listado comercios", response);
        obj.comercios=response.data
      })
      .catch(function(error) {
        console.log(error)
        // obj.$swal.fire({
        //     title: "Error",
        //     text: "Sin datos cargados",
        //     icon: "error"
        //   });
      });
    },

    // EliminarComercio(){
    //   var obj = this;
    //     this.$ajax
    //     .delete(`${this.$apitramites}comercios/${this.pkcomercio}`)
    //     .then(function(response) {
    //       console.log("Eliminar comercio", response);
    //       obj.snackbarconfirm=true;
    //       obj.msj= "Comercio eliminado con exito!!!";
    //       obj.ListadoComercio();
    //       obj.DialogEliminarcomercio=false;
    //     })
    //     .catch(function(error) {
    //       obj.snackbar=true;
    //       obj.msj= error;
    //     });
    // },

    EliminarComercio(item){
      this.pkcomercio=item.pkcomercio;
      console.log(item);
      var obj = this;
      this.$swal.fire({
        title: "¿Desea eliminar este comercio?",
        text: "¡Esta acción no se puede revertir!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, cancelar!",
        confirmButtonText: "Sí, eliminar!",
      }).then((result) => {
        if (result.isConfirmed) {
          obj.$ajax
            .delete(`${obj.$apitramites}comercios/${obj.pkcomercio}`)
            .then(function(response) {
              console.log("Eliminar comercio", response);
              obj.$swal.fire({
                title: "¡Eliminado!",
                text: "Comercio eliminado con éxito",
                icon: "success"
              });
              obj.ListadoComercio();
            })
            .catch(function(error) {
              console.error("Error al eliminar comercio", error);
              obj.$swal.fire({
                title: "Error",
                text: "Hubo un error al eliminar el comercio",
                icon: "error"
              });
            });
        }
      });
    },

    Editarcomercio(){
      var obj = this;
      this.$ajax
      .patch(`${this.$apitramites}comercios/${this.pkcomercio}`, {descripcion: this.editardescripcion, cuenta:this.padron})
      .then(function(response) {
        console.log("Editar comercio", response);
        obj.$swal.fire({
                title: "¡Editado!",
                text: "Comercio editado con éxito",
                icon: "success"
              });
        obj.expanded=[]
        obj.ListadoComercio();
      })
      .catch(function(error) {
        obj.$swal.fire({
                title: "Error",
                text: error,
                icon: "error"
              });
      });
    },

    laclase(item){
      if(item.condicontri != '2'){
        if(item.t_desvie == '1' && item.t_lugar == '1'){
          return {class:"green--text" , restringido: false};
        }else if( ((item.t_desvie == '1' && item.t_lugar =='0') || (item.t_desvie =='0' && item.t_lugar =='1')) && item.t_web == '1'){
          return {class:"orange--text" , restringido: false};
        }else{
          return {class:"red--text" , restringido: true};
        }
      }else{
          return {class:"red--text" , restringido: true};
      }
           
    },

    AgregarComercio(event){
      var obj = this;
      if (this.$refs.formnuevocomercio.validate()) {
        console.log("hola", Object.fromEntries(new FormData(event.target)));
        let objetito = Object.fromEntries(new FormData(event.target));
        objetito.padron = Number(objetito.padron)

        this.$ajax
        .post(`${this.$apitramites}comercios`, objetito)
        .then(function(response) {
          console.log("Nuevo comercio", response);
          obj.$swal.fire({
                title: "¡Agregado!",
                text: "Comercio cargado con éxito",
                icon: "success"
              });
          obj.ListadoComercio();
          obj.DialogNuevocomercio=false;
          obj.$refs.formnuevocomercio.reset();
        })
        .catch(function(error) {
          let mensaje = "Error, intente nuevamente"
          switch (error.response.data.message) {
            case "NOT_EXIST":
                mensaje= "No se encuentra el comercio"
              break;
            case "ROW_EXIST":
                mensaje="El comercio ya está asociado a su cuenta"
              break;
          }
          obj.$swal.fire({
                title: "Error",
                text: mensaje,
                icon: "error"
              });
        });
      }
    },

    ConstanciaHabilitacion(item) {
      var obj = this;
      this.$ajax({
                  method: 'post',
                  url: `${this.$apitramites}comercios/imprimirConstanciaHabilitacion`,
                  responseType: 'blob',
                  data: { 
                    padron: item.padron
                  }
                })
        .then(function (response) {
          console.log("certificado", response);
          // obj.ImprimirPdf(response.data);
          const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });
            const pdfUrl = window.URL.createObjectURL(pdfBlob);
            window.open(pdfUrl);
          // obj.PdfCertificado(response.data);
        })
        .catch(function (error) {
          obj.$swal.fire({
            title: "Error",
            text: error.message || "Ha ocurrido un error al generar el certificado.",
            icon: "error",
          });
        });
    },

    AbrirDialogDeudas(){
      console.log("ref", this.$refs)
      console.log("ref2", this.$refs["formDeuda"])
      this.$refs['formDeuda'];
      // this.$refs['pagardeudasform'].ListadoCuotas();
      //this.$refs['pagardeudasform'].DialogDeudas = !this.$refs['pagardeudasform'].DialogDeudas;
    },

    AbrirDialogPagas(){
      this.$refs['formcuotaspagas'];
    },

    AbrirDialogPlanDePagos(){
      console.log("entra aca si?")
      this.$refs['pagarplandepagosform'];
    },

    // AbrirDialogCuotasPagas(){
    //   this.$refs['cuotaspagasform'].ListadoCuotas();
    // },
    AbrirDialogDeclaracionJurada(){
      console.log("entra aca")
      this.$refs['formDeclaracion'];
    },
    // AbrirDialogPresentacionAnual(){
    //   console.log("ref", this.$refs)
    //   console.log("ref2", this.$refs["formPresentacion"])
    //   this.$refs['formPresentacion'];
    // },

    vaciarExpanded(){
      this.expanded=[];
    }

  },
};
</script>
