<template>
  <v-card outlined>
    <v-data-table
      :headers="headerscuotas"
      :items="cuotas"
      item-key="cunica"
      class="custom-header-style"
      :items-per-page="-1"
      group-by="anio"
      ref="tablacuotas"
      show-select
      hide-default-footer
      disable-sort
    >
        <template v-slot:[`header.data-table-select`]>
            <!-- <v-btn dark color="claro" class="mr-2" @click="GenerarExcel()">
                excel
            </v-btn> -->
            <v-col></v-col>
        </template>
        <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
            <th :colspan="2">
            <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
            </v-icon>
            {{ items[0].anio }}
            </th>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-center">${{ SumaPorAnio(items, "original") }}</th>
            <th class="text-center">${{ SumaPorAnio(items, "actualizado") }}</th>
            <th class="text-center">${{ SumaPorAnio(items, "descuento") }}</th>
            <th class="text-center">${{ SumaPorAnio(items, "total") }}</th>
        </template>
      <template v-slot:[`item`]="{ item }">
        <tr>
            <th></th>
          <th class="text-center"><p class="font-weight-regular">{{item.cuota}}</p></th>
          <th class="text-center"><p class="font-weight-regular">{{item.tasa}}</p></th>
          <th class="text-center"><p class="font-weight-regular">{{item.obra}}</p></th>
          <th class="text-center"><p class="font-weight-regular">{{item.fecha_ven1 | moment("DD/MM/YYYY")}}</p></th>
          <th class="text-center"><p class="font-weight-regular">{{Estado(item)}}</p></th>
          <th class="text-center"><p class="font-weight-regular">${{ formatNumber(item.importe)}}</p></th>
          <th class="text-center"><p class="font-weight-regular">${{ formatNumber(item.recargo) }}</p></th>
          <th class="text-center"><p class="font-weight-regular">${{ formatNumber(item.descuento) }}</p></th>
          <th class="text-center"><p class="font-weight-bold">${{ formatNumber(item.totalcuota) }}</p></th>
        </tr>
      </template>
    </v-data-table>
    <v-card-actions class="justify-center">
      <v-btn dark color="error" class="mr-2" @click="vaciarExpanded()">
        cancelar
      </v-btn>
      <v-btn dark color="claro" class="mr-2" @click="GenerarExcel()">
        Generar excel
      </v-btn>
    </v-card-actions>
  </v-card>

  <!-- </v-col> -->

  <!-- </v-dialog> -->
</template>

<script>
// import pagardeuda from "../../views/Pagar_deuda_inmueble";
import {json2excel} from 'js2excel';
export default {
  props: {
    user: Object,
  },
  data: () => ({
    checksito: false,
    headerscuotas: [
        {
        text: "AÑO",
        align: "center",
        value: "anio",
        class: "white--text",
        group: {
          open: false,
        },
      },
      {
        text: "CUOTA",
        value: "cuota",
        align: "center",
        class: "white--text",
      },
      {
        text: "TASA",
        value: "tasa",
        align: "center",
        class: "white--text",
      },
      {
        text: "OBRA",
        value: "obra",
        align: "center",
        class: "white--text",
      },
      {
        text: "VENCIMIENTO",
        value: "fecha_vencimiento",
        align: "center",
        class: "white--text",
      },
      {
        text: "ESTADO",
        value: "nomEstado",
        align: "center",
        class: "white--text",
      },
      {
        text: "ORIGINAL",
        value: "original_lindo",
        class: "white--text",
        align: "center",
      },
      {
        text: "RECARGO",
        value: "recargos_lindo",
        class: "white--text",
        align: "center",
      },
      {
        text: "DESCUENTO",
        value: "descuento_lindo",
        class: "white--text",
        align: "center",
      },
      {
        text: "TOTAL A PAGAR",
        value: "total_lindo",
        class: "white--text",
        align: "center",
      },
    ],
    cuotas: [],
    cuotasseleccionadas: [],
  }),

  computed: {
    suma_deudas() {
      return `${
        this.datospagardeuda.totalCuotas +
        this.datospagardeuda.totalCuotasJuicio +
        this.datospagardeuda.totalCuotasPlan
      }`;
    },
    cunicasseleccionadas() {
      return this.cuotasseleccionadas.map((v) => {
        return v.cunica;
      });
    },
  },

  watch: {},

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.ListadoCuotas();
    },

    Estado(item) {
      return item.convenio > 0
        ? `Plan ${item.estadonew} ${item.nomEstado}`
        : item.nomEstado;
    },

    ListadoCuotas() {
      this.cuotas = [];
      this.cuotasseleccionadas = [];
      this.datospagardeuda = [];
      console.log("usuario en cuotasss", this.user);
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}comercios/cuotasPagas/${this.user.datoscomercio.padron}`)
        .then(function (response) {
          console.log("Listado Cuotas pagas", response);
          obj.datospagardeuda = response.data;
          obj.cuotas = response.data.cuotasF;
          console.log("Listado Cuotas comercio 2", obj.cuotas);
          obj.$nextTick(() => {
            console.log("DataTable renderizado completamente.");
            var cuotasGrupo =
              obj.$refs.tablacuotas.$el.querySelectorAll(".mdi-minus");
            console.log("cuotiii", cuotasGrupo);
            for (var i = 0; i < cuotasGrupo.length; i++) {
              cuotasGrupo[i].click();
            }
          });
          // obj.DialogDeudas=true;
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },


    SumaPorAnio(items, valor) {
      if (valor === "total") {
        const total = items.reduce((sum, item) => sum + item.totalcuota, 0);
        return this.formatNumber(total);
      }
      if (valor === "original") {
        const original = items.reduce((sum, item) => sum + item.importe, 0);
        return this.formatNumber(original);
      }
      if (valor === "actualizado") {
        const actualizado = items.reduce((sum, item) => sum + item.recargo, 0);
        return this.formatNumber(actualizado);
      }
      if (valor === "descuento") {
        const descuento = items.reduce((sum, item) => sum + item.descuento, 0);
        return this.formatNumber(descuento);
      }
    },
    formatNumber(number) {
      if (number >= 1000) {
        const formatted = new Intl.NumberFormat("es-ES").format(number);
        const parts = formatted.split(",");
        if (parts.length === 2) {
          return (
            parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "," + parts[1]
          );
        }
        return formatted;
      } else {
        return new Intl.NumberFormat("es-ES", {
          minimumFractionDigits: 2,
        }).format(number);
      }
    },

    GenerarExcel(){
        // var cuotas = this.cuotas;
        // try {
        //     json2excel({
        //     data: cuotas,
        //     name: "Cuotas_Pagas_Comercio",
        //     });
        // } catch (e) {
        //     console.log("export error");
        // }
        var listado = this.cuotas;
        listado = listado.map((v)=>{
            return {
            "AÑO": v.anio,
            "CUOTA": v.cuota,
            "TASA": v.tasa,
            "OBRA": v.obra,
            "VENCIMIENTO": v.fecha_ven1,
            "ESTADO": v.nomEstado,
            "ORIGINAL": this.formatNumber(v.importe),
            "RECARGO": this.formatNumber(v.recargo),
            "DESCUENTO": this.formatNumber(v.descuento),
            "TOTAL A PAGAR": this.formatNumber(v.totalcuota),
            }
        });
        try{
            json2excel({
            data: listado,
            name: "Listado_cuotaspagas_comercio",
            });
        }catch(e){
            console.log("export error");
        }
    },

    vaciarExpanded() {
      this.$emit("vaciarExpanded");
    },
  },
};
</script>
<style scoped>
.tabla {
  border: 2px solid;
  border-radius: 20px;
  border-color: #638699;
  padding: 0px;
}
.items_tabla{
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
</style>
